<template>
  <div
    v-if="hasAside && showAside"
    :class="{ 'show-aside': $store.state.ui.adminAside }"
    class="admin-aside"
  >
    <div class="admin-aside-controls">
      <a
        v-if="!is1680 && showAside"
        class="button is-white"
        @click="$store.commit('ui/adminAside', false)"
      >
        <b-icon icon="angle-right" />
      </a>
    </div>

    <div class="admin-aside-view">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminAside",
  computed: {
    hasAside() {
      const matchedRoute = this.$route.matched[this.$route.matched.length - 1];
      return this.$_.has(matchedRoute.components, "aside");
    },
    is1680() {
      return this.$store.state.ui.window.width >= 1680;
    },
    showAside() {
      return this.is1680 || this.$store.state.ui.adminAside;
    },
    showMenu() {
      return this.$store.state.ui.adminMenu;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.admin-aside {
  display: grid;
  width: 400px;
  grid-template-rows: 4rem 1fr;
  overflow: auto;
  background: $light;
  grid-row: 1;

  @include mobile {
    top: 4rem;
    max-width: 100%;
    // grid-row: 2;
  }

  @include tablet {
    top: 0;
  }

  @media (max-width: 1679px) {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 90;
    box-shadow: -400px 0 0 200vw rgba($dark, 0.5);
    &:not(.show-aside) {
      display: none;
    }
  }

  @media (min-width: 1680px) {
    position: relative;
    grid-row: 1;
    grid-column: 4;
    border-left: 1px solid $border;
  }

  .admin-aside-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: $white;
    border-bottom: 1px solid $border;
    padding: 0.75em;
  }

  .admin-aside-view {
    overflow: auto;
    padding-bottom: 5rem;
  }
}
</style>
